import LoginForm from "../../../Components/Authentication/LoginForm"

const Login = () => {    

    return (
        <>
        <div className="container vh-100 d-flex flex-column align-items-center justify-content-center">
            <h2>Login</h2>
        <LoginForm />
        </div>
        </>
    )
}


export default Login