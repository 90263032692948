const ViewDocumentButton = ({ title, handleSubmit }) => {
  return (
    <button
      title={title}
      className="btn btn-outline-danger"
      onClick={handleSubmit}
    >
      {title}
    </button>
  );
};

export default ViewDocumentButton;
