import axios from "axios";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils/localStorage";
import { constants } from "../lib/constants";

let axiosClient = axios.create({
  timeout: 100000,
});

axiosClient.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = "Bearer " + getLocalStorageItem("token");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const sendRequest = (
  path,
  method = "GET",
  data = {},
  headers = null,
  contentType = "application/json"
) => {
  return new Promise((resolve, reject) => {
    const params = {
      url: constants.DOMAIN_URL + path,
      method,
      headers: {
        "Content-Type": contentType,
        ...(headers || {}),
      },
      params: {},
      data: {},
    };

    if (data !== null) {
      if (method == "GET") {
        params.params = data;
      } else {
        params.data = data;
      }
    }

    console.log(params);

    axiosClient(params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          console.log("error", error.response.status);
          removeLocalStorageItem("token");
          window.location.reload();
        }
        return reject(error?.response?.data);
      });
  });
};
