import React from "react";
import CompaniesList from "./CompaniesList";

const Companies = (props) => {
  return (
    <>
      <div className="">
        <div className="top-header">
          <div className="page-name">
            <h4>Companies</h4>
          </div>
        </div>

        <CompaniesList />
      </div>
    </>
  );
};

export default Companies;
