import '../src/Css/style.css';
import '../src/Css/responsive.css';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { ThemeProvider } from './hooks/useThemeContext';
import AppRouter from './AppRouter';

function App() {
  return (

    <>
      <ThemeProvider>
          <Router>
            <AppRouter />
          </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
