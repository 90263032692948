import { useEffect, useState } from "react";
import { deleteCompany, getCompanyList } from "../Services/admin-service";
import { toast } from "react-toastify";

const useCompany = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  const loadData = (options) => {
    setLoading(true);
    getCompanyList(options)
      .then((res) => {
        setCompanyList(res.data.docs);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCompany = (companyIdN) => {
    setShow(true);
    setCompanyId(companyIdN);
  };

  useEffect(()=>{
    if(!show){
      setCompanyId(null)
    }
  },[show])

  const deleteHandler = async (companyIdN) => {
    try {
      const deleteRes = await deleteCompany(companyIdN);
      if (deleteRes.status === 200) {
        toast("Company Delete Successfully");
        loadData({ page: currentPage, searchTerm: search });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    loadData({ page: currentPage, searchTerm: search });
  }, [currentPage, search]);

  return {
    deleteHandler,
    updateCompany,
    setCurrentPage,
    setCompanyId,
    currentPage,
    loading,
    setShow,
    totalPages,
    show,
    companyList,
    handlePageChange,
    handleSearch,
    loadData,
    companyId,
  };
};

export default useCompany;
