export const endpoint = {
  auth: {
    LOGIN: "/auth/login",
  },
  card: {
    SEARCH: "/card/search/list",
    ADD: "/card",
    UPDATE: "/card/update/",
    DELETE: "/card/delete/",
    INFO: "/card/",
    CARDS_WEBSITE: "/card/cards/cards-website",
    CARDS_PRICES: "/card/cards-prices",
    SUBSCRIBER: "/card/getall-subscriber",
    REQUEST: "/card/getRequest",
    FILTER_OPTIONS: "/card/search-languages"
  },
  company: {
    SEARCH: "/company/search/list",
    ADD: "/company",
    UPDATE: "/company/update/",
    DELETE: "/company/delete/",
    INFO: "/company/",
  },
};
