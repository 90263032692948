import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputGroup, Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import ViewDocumentButton from "../../../Components/ViewDocumentButton";
import { formatDateWithTime, imageUrlBasePath } from "../../../utils/helpers";
import Loader from "../../../Components/Loader";
import useCompany from "../../../hooks/useCompany";
import CompanyModel from "../../../Components/Models/CompanyModel";

const CompaniesList = (props) => {
  const {
    deleteHandler,
    updateCompany,
    handlePageChange,
    handleSearch,
    setShow,
    currentPage,
    companyList,
    search,
    loading,
    totalPages,
    loadData,
    show,
    companyId,
    setCompanyId,
  } = useCompany();

  const renderCompanyImage = (rowData) => {
    return (
      <img
        src={imageUrlBasePath(rowData.image)}
        alt={rowData.name}
        className="image-card"
      />
    );
  };

  const renderActions = (rowData) => {
    return (
      <div className="action-button">
        <ViewDocumentButton
          cardId={rowData._id}
          title="Update"
          handleSubmit={() => updateCompany(rowData._id)}
        />
        <ViewDocumentButton
          cardId={rowData._id}
          title="Delete"
          handleSubmit={() => deleteHandler(rowData._id)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="serachbar-box">
        <div className="searchbar">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <AiOutlineSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Company"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={search}
              onChange={handleSearch}
            />
          </InputGroup>
        </div>
        <button
          className="btn btn-outline-danger"
          onClick={() => {
            setCompanyId(null);
            setShow(true);
          }}
        >
          Add Company
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable value={companyList} tableStyle={{ width: "100%" }}>
            <Column field="name" header="Company Name"></Column>
            <Column field="isVerified" header="Verified"></Column>
            <Column header="Image" body={renderCompanyImage} />
            <Column field="createdAt" header="Created At" body={(item) => formatDateWithTime(item?.createdAt)}></Column>
            <Column field="updatedAt" header="Updated At" body={(item) => formatDateWithTime(item?.updatedAt)}></Column>
            <Column header="Action" body={renderActions}></Column>
          </DataTable>
          <div className="custom-pagination justify-content-between d-flex align-items-center py-4">
            <button
              className="btn btn-primary me-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="rounded-pill text-md">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              className="btn btn-primary ms-2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}

      <CompanyModel
        companyId={companyId}
        show={show}
        setShow={setShow}
        loadData={loadData}
      />

    </>
  );
};

export default CompaniesList;
