import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputGroup, Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";
import {
  getRequestDetails,
  subscriberDetails,
} from "../../../Services/admin-service";
import Loader from "../../../Components/Loader";
import { formatDateWithoutTime } from "../../../utils/helpers";

const RequestList = () => {
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [product, setProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearch = (e) => {
    setProduct(e.target.value);
  };

  const fetchSubscribers = async () => {
    setLoading(true);
    try {
      const response = await getRequestDetails({
        page: currentPage,
        productTerm: product,
      });
      console.log("response subscriber", response);
      setSubscribers(response.data.docs);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching subscribers:", error);
      toast.error("Error fetching subscribers");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchSubscribers();
  }, [currentPage, product]);

  const renderDate = (rowData) => {
    return formatDateWithoutTime(rowData.createdAt);
  };

  return (
    <>
      <div className="top-header">
        <div className="page-name">
          <h4>Requests</h4>
        </div>
      </div>
      <br />
      <br />
      <div className="searchbar-box">
        <div className="searchbar">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <AiOutlineSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Products"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={product}
              onChange={handleSearch}
            />
          </InputGroup>
        </div>
      </div>
      <br />
      <br />
      <>
        <DataTable value={subscribers} tableStyle={{ width: "100%" }}>
          <Column field="product" header="Product"></Column>
          <Column header="Date" body={renderDate} />
        </DataTable>
        <div className="custom-pagination justify-content-between d-flex align-items-center py-4">
          <button
            className="btn btn-primary me-2"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="rounded-pill text-md">{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            className="btn btn-primary ms-2"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </>
    </>
  );
};

export default RequestList;
