import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ViewDocumentButton from "../ViewDocumentButton";
import { InputGroup, Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import { formatDateWithoutTime, formatDateWithTime, imageUrlBasePath } from "../../utils/helpers";
import CardModel from "../Models/CardModel";
import useCard from "../../hooks/useCard";
import Loader from "../Loader";

const CardList = (props) => {
  const {
    deleteHandler,
    updateCard,
    handlePageChange,
    handleSearch,
    setShow,
    currentPage,
    cardList,
    search,
    loading,
    totalPages,
    loadData,
    show,
    cardId,
    setCardId,
  } = useCard();

  const renderReleaseDate = (rowData) => {
    return formatDateWithoutTime(rowData.releaseDate);
  };

  const renderCardImage = (rowData) => {
    return (
      <img
        src={imageUrlBasePath(rowData.cardUrl)}
        alt={rowData.cardName}
        className="image-card"
      />
    );
  };

  const renderActions = (rowData) => {
    return (
      <div className="action-button">
        <ViewDocumentButton
          cardId={rowData._id}
          title="Update"
          handleSubmit={() => updateCard(rowData._id)}
        />
        <ViewDocumentButton
          cardId={rowData._id}
          title="Delete"
          handleSubmit={() => deleteHandler(rowData._id)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="serachbar-box">
        <div className="searchbar">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <AiOutlineSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Card"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={search}
              onChange={handleSearch}
            />
          </InputGroup>
        </div>
        <button
          className="btn btn-outline-danger"
          onClick={() => {
            setCardId(null);
            setShow(true);
          }}
        >
          Add Card
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <DataTable value={cardList} tableStyle={{ width: "100%" }}>
            <Column header="Image" body={renderCardImage} />
            <Column field="cardName" header="Card Name"></Column>
            {/* <Column field="language" header="Language"></Column> */}
            {/* <Column field="era" header="Era"></Column>
            <Column field="type" header="Type"></Column> */}
            {/* <Column header="Release Date" body={renderReleaseDate} /> */}
            <Column field="CSVFile" header="CSV File"></Column>
            <Column field="createdAt" header="Created At" body={(item) => formatDateWithTime(item?.createdAt)}></Column>
            <Column field="updatedAt" header="Updated At" body={(item) => formatDateWithTime(item?.updatedAt)}></Column>
            <Column header="Action" body={renderActions}></Column>
          </DataTable>
          <div className="custom-pagination justify-content-between d-flex align-items-center py-4">
            <button
              className="btn btn-primary me-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="rounded-pill text-md">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              className="btn btn-primary ms-2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}

      <CardModel
        cardId={cardId}
        show={show}
        setShow={setShow}
        loadData={loadData}
      />
    </>
  );
};

export default CardList;
