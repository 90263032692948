import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {
  MdBoy,
  MdCardGiftcard,
  MdCloudUpload,
  MdOutlinePeople,
  MdOutlinePriceChange,
  MdOutlinePriceCheck,
  MdPriceChange,
  MdPriceCheck,
  MdViewList,
} from "react-icons/md";
import { BsUnindent, BsVoicemail } from "react-icons/bs";
import { removeLocalStorageItem } from "../../../utils/localStorage";
import { cardsPrices, cardsWebsite } from "../../../Services/admin-service";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { Spin } from "antd";

const Superadminsidebar = () => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [uFiles, setUFiles] = useState([])

  const handleLogout = () => {
    try {
      removeLocalStorageItem("token");
    } catch (error) {
      console.log("Error while logging out!");
    }
  };

  const processCardFile = async (index, file) => {
    const fileName = `${file?.newName}` 
    try {
      const formData = new FormData();
      formData.append('file', file);
      await cardsWebsite(formData);
      toast.success(`${fileName} processed successfully`);
    } catch (error) {
      toast.error(`${fileName} failed to prcoess ${fileName}. ${error?.message}`);
    } finally {
      removeFileFromQueue(fileName)
    }
  }

  const addFilesToQueue = (files, prefix = null)=>{
    for (let i = 0; i < files.length; i++) {
      let fileName = `${files[i]?.name}` 
      if(prefix){
        fileName = `${prefix} ${fileName}`
      }
      files[i].newName = fileName
    }
    setUFiles((prevFiles) => [...prevFiles, ...files]);
    return files
  }

  const removeFileFromQueue = (fileName) => {
    setUFiles(prevFiles => prevFiles.filter(file => file.newName !== fileName));
  }

  const handleFileChange = async (event) => {
    const files = event.target.files;
    addFilesToQueue(files, 'Card File')
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // setTimeout(() => {
      //   processCardFile(i, file)
      // }, 4000 * i)
      processCardFile(i, file)
    }
  };

  const handleCardFile = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv";
    fileInput.multiple = true;
    fileInput.style.display = "none";
    fileInput.onchange = handleFileChange;
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  const handlePriceChange = async (event) => {
    let file = event.target.files[0];
    if (file) {
      const filesData = addFilesToQueue([file], 'Price Comparison')
      file = filesData[0]
      const fileName = `${file?.newName}`
      try {
        let formData = new FormData();
        formData.append("file", file);
        await cardsPrices(formData);
        toast.success(`${fileName} processed successfully`);
      } catch (error) {
        toast.error(`${fileName} failed to prcoess ${fileName}. ${error?.message}`);
      } finally {
        removeFileFromQueue(fileName)
      }
    }  
  };

  const handlePriceComparsion = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv";
    fileInput.style.display = "none";
    fileInput.onchange = handlePriceChange;
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  console.log(uFiles)

  return (
    <>
      {loading && <Loader />}

      <div className="Sidebarlayout">
        <Navbar className="mainnavbar">
          <h4 className="navbar-title">TCG</h4>
          {
            uFiles.length > 0 ? <>
              <div className="file-upload-status">
                <Spin />
                <span>Uploading:</span> <span><strong>{uFiles.length}</strong>Left</span>
              </div>
            </> : null
          }
          <Nav className="mb-5">
            <NavLink to="/cards">
              <MdCardGiftcard />
              Cards
            </NavLink>
          </Nav>
          <Nav className="mb-5">
            <NavLink onClick={handleCardFile}>
              <MdCloudUpload /> Upload Card Files
            </NavLink>
          </Nav>
          <Nav className="mb-5">
            <NavLink onClick={handlePriceComparsion}>
              <MdPriceChange /> Upload Price Comparisons
            </NavLink>
          </Nav>
          <Nav className="mb-5">
            <NavLink to="/subscribers">
              <MdOutlinePeople />
              Subscribers
            </NavLink>
          </Nav>
          <Nav className="mb-5">
            <NavLink to="/request">
              <MdCardGiftcard />
              Requests
            </NavLink>
          </Nav>
          <Nav className="mb-5">
            <NavLink to="/companies">
              <MdViewList />
              Companies
            </NavLink>
          </Nav>
        </Navbar>
        <Navbar className="mainnavbar logoutbar">
          <NavLink to="/" onClick={handleLogout}>
            <BsUnindent />
            Logout
          </NavLink>
        </Navbar>
      </div>
    </>
  );
};

export default Superadminsidebar;
