import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  addCard,
  getCardInfo,
  getFilterOptions,
  updateCardService,
} from "../../Services/admin-service";
import {
  capitalizeFirstLetter,
  haveValue,
  imageUrlBasePath,
  objectToFormData,
} from "../../utils/helpers";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import InputDatePicker from "../Input/InputDatePicker/InputDatePicker";

function CardModel({ show, setShow, loadData = () => {}, cardId = null }) {
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);

  const [stateTime, setStateTime] = useState(0);
  const [filterLangs, setFilterLangs] = useState([]);
  const [filterEras, setFilterEras] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterSets, setFilterSets] = useState([]);

  const initData = {
    cardName: "",
    isPopular: false,
    releaseDate: null,
    language: "",
    era: "",
    type: "",
    set: "",
    CSVFile: "",
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initData,
    mode: "all",
  });

  const handleClose = () => setShow(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLanguageChange = (newValue) => {
    setValue("language", newValue ? newValue.value : "");
    setStateTime(new Date().getTime());
  };

  const handleEraChange = (newValue) => {
    setValue("era", newValue ? newValue.value : "");
    setStateTime(new Date().getTime());
  };

  const handleTypeChange = (newValue) => {
    setValue("type", newValue ? newValue.value : "");
    setStateTime(new Date().getTime());
  };

  const handleSetChange = (newValue) => {
    setValue("set", newValue ? newValue.value : "");
    setStateTime(new Date().getTime());
  };

  useEffect(() => {
    if (cardId) {
      getCardInfo(cardId)
        .then((res) => {
          const { data } = res;
          reset({
            cardName: data.cardName,
            isPopular: data.isPopular,
            releaseDate: new Date(data.releaseDate),
            language: data.language,
            era: data.era,
            type: data.type,
            set: data.set,
            CSVFile: data.CSVFile,
          });
          setImagePreview(imageUrlBasePath(data.cardUrl));
        })
        .catch((err) => console.error("Error fetching card info:", err));
    } else {
      reset(initData);
      setImagePreview(null);
    }
  }, [cardId, reset]);

  const addCardSubmit = async (data) => {
    try {
      const newUpdate = objectToFormData({ ...data, file });
      const addRes = await addCard(newUpdate);
      if (addRes.status === 200) {
        toast.success("Card Added Successfully");
        loadData();
        setShow(false);
        reset(initData);
        setImagePreview(null);
        setFile(null);
        loadFilterOptions();
      }
    } catch (error) {
      console.error("Error adding card:", error);
      toast.error(error?.message);
    }
  };

  const updateCardSubmit = async (data) => {
    try {
      const newUpdate = objectToFormData({ ...data, file });
      const updateRes = await updateCardService(cardId, newUpdate);
      if (updateRes.status === 200) {
        toast.success("Card Updated Successfully");
        loadData();
        handleClose();
        loadFilterOptions();
        setImagePreview(null);
        setFile(null);
      }
    } catch (error) {
      console.error("Error updating card:", error);
      toast.error(error.message);
    }
  };

  const loadFilterOptions = () => {
    getFilterOptions().then((res) => {
      const data = res.data;
      setFilterLangs(data?.languages ?? []);
      setFilterEras(data?.eras ?? []);
      setFilterSets(data?.sets ?? []);
      setFilterTypes(data?.types ?? []);
    });
  };

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Form
        onSubmit={
          haveValue(cardId)
            ? handleSubmit(updateCardSubmit)
            : handleSubmit(addCardSubmit)
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{cardId ? "Edit Card" : "Add Card"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="cardName">
            <Form.Label>Card Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Card Name"
              {...register("cardName", { required: true })}
            />
            {errors.cardName && (
              <p className="error-color">Card Name is required.</p>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="releaseDate">
            <Form.Label>Release Date</Form.Label>
            <InputDatePicker
              name="releaseDate"
              value={getValues("releaseDate")}
              onChange={(e) => {
                setValue("releaseDate", e.target.value.toString());
                setStateTime(new Date().getTime());
              }}
              className="enter-number-filed pick-date"
            />
            {errors.releaseDate && (
              <p className="error-color">Release Date is required.</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Card Image</Form.Label>
            {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="Card"
                  style={{ width: "100%", marginBottom: "10px" }}
                />
              </div>
            )}
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
          <Row className="mb-3">
            <Col xs="auto">
              <Form.Group controlId="isPopular">
                <Form.Check
                  type="checkbox"
                  id="isPopular"
                  label="Is Popular"
                  {...register("isPopular")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="language">
            <Form.Label>Language</Form.Label>
            <CreatableSelect
              isClearable
              options={Object.values(filterLangs).map((lang) => ({
                label: lang,
                value: lang,
              }))}
              onChange={handleLanguageChange}
              value={{
                value: getValues("language"),
                label: getValues("language"),
              }}
            />
            {errors.language && (
              <p className="error-color">Language is required.</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="era">
            <Form.Label>ERA</Form.Label>
            <CreatableSelect
              isClearable
              options={Object.values(filterEras).map((era) => ({
                label: era,
                value: era,
              }))}
              onChange={handleEraChange}
              value={{ value: getValues("era"), label: getValues("era") }}
            />
            {errors.era && <p className="error-color">ERA is required.</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="type">
            <Form.Label>Type</Form.Label>
            <CreatableSelect
              isClearable
              options={Object.values(filterTypes).map((type) => ({
                label: type,
                value: type,
              }))}
              onChange={handleTypeChange}
              value={{ value: getValues("type"), label: getValues("type") }}
            />
            {errors.type && <p className="error-color">Type is required.</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="set">
            <Form.Label>Set</Form.Label>
            <CreatableSelect
              isClearable
              options={Object.values(filterSets).map((set) => ({
                label: set,
                value: set,
              }))}
              onChange={handleSetChange}
              value={{ value: getValues("set"), label: getValues("set") }}
            />
            {errors.set && <p className="error-color">Set is required.</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="CSVFile">
            <Form.Label>CSV File (Without extension)</Form.Label>
            <Form.Control
              type="text"
              {...register("CSVFile", { required: true })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CardModel;
