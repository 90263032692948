import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getCardList = (payload) => {
  return sendRequest(endpoint.card.SEARCH, "GET", payload);
};

export const updateCardService = (cardId, payload) => {
  return sendRequest(
    endpoint.card.UPDATE + cardId,
    "PUT",
    payload,
    null,
    "multipart/form-data"
  );
};
export const deleteCard = (cardId) => {
  return sendRequest(endpoint.card.DELETE + cardId, "DELETE");
};

export const addCard = (payload) => {
  console.log("payload::::", payload);
  return sendRequest(
    endpoint.card.ADD,
    "POST",
    payload,
    null,
    "multipart/form-data"
  );
};

export const getCardInfo = (cardId) => {
  return sendRequest(endpoint.card.INFO + cardId, "GET");
};

export const cardsWebsite = (payload) => {
  return sendRequest(
    endpoint.card.CARDS_WEBSITE,
    "POST",
    payload,
    null,
    "multipart/form-data"
  );
};

export const cardsPrices = (payload) => {
  return sendRequest(
    endpoint.card.CARDS_PRICES,
    "POST",
    payload,
    null,
    "multipart/form-data"
  );
};

export const subscriberDetails = (payload) => {
  return sendRequest(endpoint.card.SUBSCRIBER, "GET", payload);
};

export const getRequestDetails = (payload) => {
  return sendRequest(endpoint.card.REQUEST, "GET", payload);
};


export const getCompanyList = (payload) => {
  return sendRequest(endpoint.company.SEARCH, "GET", payload);
};

export const updateCompanyService = (companyId, payload) => {
  return sendRequest(
    endpoint.company.UPDATE + companyId,
    "PUT",
    payload,
    null,
    "multipart/form-data"
  );
};
export const deleteCompany = (companyId) => {
  return sendRequest(endpoint.company.DELETE + companyId, "DELETE");
};

export const addCompany = (payload) => {
  return sendRequest(
    endpoint.company.ADD,
    "POST",
    payload,
    null,
    "multipart/form-data"
  );
};

export const getCompanyInfo = (companyId) => {
  return sendRequest(endpoint.company.INFO + companyId, "GET");
};

export const getFilterOptions = () => {
  return sendRequest(endpoint.card.FILTER_OPTIONS, "GET");
};