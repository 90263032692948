import React, { useState, useEffect } from "react";

import BackButton from "../../../Components/BackToPrev";
import CardList from "../../../Components/Driver/CardList";

const Card = (props) => {
  return (
    <>
      <div className="">
        <div className="top-header">
          <div className="page-name">
            <h4>Cards</h4>
          </div>
        </div>

        <CardList />
      </div>
    </>
  );
};

export default Card;
