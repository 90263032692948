import { useEffect } from "react";
import "../src/Css/style.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getLocalStorageItem } from "./utils/localStorage";
import SuperAdminLayout from "./Components/Layout/SuperAdminLayout";
import Login from "./Pages/SuperAdminPages/Authentication/login";
import Card from "./Pages/SuperAdminPages/Cards/Card";
import SubscriberList from "./Pages/SuperAdminPages/subscriber/subscriber";
import RequestList from "./Pages/SuperAdminPages/Request/Request";
import Companies from "./Pages/SuperAdminPages/Companies/Companies";

function AppRouter() {
  const history = useNavigate();
  const user_token = getLocalStorageItem("token");
  const isAuthenticated = user_token != null ? true : false;
  useEffect(() => {
    document.body.setAttribute("data-page", window?.location?.pathname);
  }, [history]);

  return (
    <>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route element={<SuperAdminLayout />}>
              <Route exact="true" path="/cards" element={<Card />} />
              <Route exact="true" path="/companies" element={<Companies />} />
              <Route path="/subscribers" element={<SubscriberList />} />
              <Route path="/request" element={<RequestList />} />
              <Route
                path="*"
                element={<Navigate to="/cards" replace={true} />}
              />
            </Route>
          </>
        ) : (
          <>
            <Route exact="true" path="/login" element={<Login />} />

            <Route path="*" element={<Navigate to="/login" replace={true} />} />
          </>
        )}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default AppRouter;
