import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  addCompany,
  getCompanyInfo,
  updateCompanyService,
} from "../../Services/admin-service";
import {
  haveValue,
  imageUrlBasePath,
  objectToFormData,
} from "../../utils/helpers";
import { toast } from "react-toastify";

function CompanyModel({ show, setShow, loadData = () => { }, companyId = null }) {
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);

  const initData = {
    name: "",
    isVerified: false
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initData,
    mode: "all",
  });

  const handleClose = () => setShow(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompanyInfo(companyId)
        .then((res) => {
          const { data } = res;
          reset({
            name: data.name,
            isVerified: data.isVerified
          });
          setImagePreview(imageUrlBasePath(data.image));
        })
        .catch((err) => console.error("Error fetching card info:", err));
    } else {
      reset(initData);
      setImagePreview(null);
    }
  }, [companyId, reset]);

  const addCompanySubmit = async (data) => {
    try {
      const newUpdate = objectToFormData({ ...data, file });
      const addRes = await addCompany(newUpdate);
      if (addRes.status === 200) {
        toast.success("Company Added Successfully");
        loadData();
        setShow(false);
        reset(initData);
        setImagePreview(null);
        setFile(null)
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const updateCompanySubmit = async (data) => {
    try {
      const newUpdate = objectToFormData({ ...data, file });
      const updateRes = await updateCompanyService(companyId, newUpdate);
      if (updateRes.status === 200) {
        toast.success("Company Updated Successfully");
        loadData();
        handleClose();
        reset(initData);
        setImagePreview(null);
        setFile(null)
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form
        onSubmit={
          haveValue(companyId)
            ? handleSubmit(updateCompanySubmit)
            : handleSubmit(addCompanySubmit)
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{companyId ? "Edit Company" : "Add Company"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="error-color">Name is required.</p>
            )}
          </Form.Group>
          <Form.Group controlId="isVerified" className="mb-3">
            <Form.Check
              type="checkbox"
              id="isVerified"
              label="Is Verified"
              {...register("isVerified")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Image</Form.Label>
            {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="Company"
                  style={{ width: "100%", marginBottom: "10px" }}
                />
              </div>
            )}
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CompanyModel;
