import { useEffect, useState } from "react";
import { deleteCard, getCardList } from "../Services/admin-service";
import { toast } from "react-toastify";
import { objectToFormData } from "../utils/helpers";

const useCard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [cardList, setCardList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState(false);
  const [cardId, setCardId] = useState(null);

  useEffect(()=>{
    if(!show){
      setCardId(null)
    }
  },[show])

  const loadData = (options) => {
    setLoading(true);
    getCardList(options)
      .then((res) => {
        setCardList(res.data.docs);
        setTotalPages(res.data.totalPages); // Correctly set totalPages from the response
        setCurrentPage(res.data.page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCard = (cardId) => {
    setShow(true);
    setCardId(cardId);
  };

  const deleteHandler = async (cardId) => {
    try {
      const deleteRes = await deleteCard(cardId);
      if (deleteRes.status === 200) {
        toast("Card Delete Successfully");
        loadData({ page: currentPage, searchTerm: search });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      // Prevent going beyond the first and last pages
      setCurrentPage(newPage);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    loadData({ page: currentPage, searchTerm: search });
  }, [currentPage, search]);

  return {
    deleteHandler,
    updateCard,
    setCurrentPage,
    setCardId,
    currentPage,
    loading,
    setShow,
    totalPages,
    show,
    cardList,
    handlePageChange,
    handleSearch,
    loadData,
    cardId,
  };
};

export default useCard;
