
export const setLocalStorageItem = (key, val) => {
  localStorage.setItem(key, val);
};

export const getLocalStorageItem = (key, defaultValue = null) => {
  let val = localStorage.getItem(key);
  if (
    val === undefined ||
    val === "undefined" ||
    val === "" ||
    val === "null"
  ) {
    val = null;
  }
  if (val === null) {
    return defaultValue;
  }
  return val;
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};
