import React from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const InputDatePicker = ({ name, className, value, onChange, type }) => {
  const changeFn = (value) => {
    console.log("value", value);
    const event = {
      target: {
        name,
        value,
        type: "date",
      },
    };
    onChange(event);
  };

  return (
    <DatePicker
      name={name}
      type={type}
      selected={value}
      className={className}
      onChange={changeFn}
      value={value}
      format="MM/dd/yyyy"
    />
  );
};

export default InputDatePicker;
